import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  useReactFlow,
  Controls,
  MiniMap,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import "./nodes.css";
import Nodes from "./nodes";
import "reactflow/dist/style.css";
import jointIcon from "../../../assets/mapsicons/joint.svg";
import loopIcon from "../../../assets/mapsicons/loopforflowicon.svg";
import FTTxIcon from "../../../assets/mapsicons/FTTx.svg";
import {
  CButton,
  CCol,
  CInput,
  CModal,
  CNav,
  CNavItem,
  CLabel,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CCard,
  CCardHeader,
} from "@coreui/react";
import LossChart from "../testdata/couplor/loss_chart";
import {
  AddLocation,
  ArrowForward,
  Delete,
  LoopSharp,
  Satellite,
} from "@material-ui/icons";
import ImageModal from "../testdata/components/image_model";
import { MapApi } from "src/Config/API";
import swal from "sweetalert";
import { CustomeCouplerShow } from "../deviceslist/edfa-ponedfa/components/Coupler/customecouplorShow";
import { CustomeLoopsShow } from "../deviceslist/edfa-ponedfa/components/loops/customLoopsShow";
import { CustomeJointsShow } from "../deviceslist/edfa-ponedfa/components/Joint/customJointShow";
import CustomeONUShow from "../deviceslist/edfa-ponedfa/components/ONU/customeONUshow";
import { CustomeORShow } from "../deviceslist/edfa-ponedfa/components/optical Reciver/customORShow";
import CustomeWDMShow from "../deviceslist/edfa-ponedfa/components/ORN/customeORNshow";

import { CustomePLCSShow } from "../deviceslist/edfa-ponedfa/components/PLC/customePLCShow";
import PLCLossChart from "../testdata/plc_splitter/plc_loss_chart";
import PolilineEditForFTTx from "../deviceslist/edfa-ponedfa/components/FiberLine/polilineEditForFTTx";
import CouplerNode from "./nodeswidgets/couplernodes.js";
import ORNode from "./nodeswidgets/ornodes";
import ORPNode from "./nodeswidgets/orpnodes";
import PLCNode from "./nodeswidgets/plcnodes";
import { useLocation } from "react-router-dom";
import MapsidePanel from "../../components/mapsidePanel";
import TheHeaderPlanScreen from "src/containers/TheHeaderplansscreen";
import CustomeFTTxshow from "../deviceslist/edfa-ponedfa/components/FTTx/customeFTTxshow";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import ONUNode from "./nodeswidgets/onunodes";
import WDMNode from "./nodeswidgets/wdmnodes";
import WDMPNode from "./nodeswidgets/wdmpnodes";
const flowKey = "example-flow";

const getNodeId = () => `randomnode_${+new Date()}`;
const nodeTypes = {
  custom: Nodes,
  couplernode: CouplerNode,
  ornode: ORNode,
  wdmnode: WDMNode,
  wdmpnode: WDMPNode,
  onunode: ONUNode,
  orpnode: ORPNode,
  plcnode: PLCNode,
};
const FTTxDraw = ({ match }) => {
  const location = useLocation();
  const [MapKey, setMapKey] = useState(location.state.mapapiKey);

  const [deviceName, setdeviceName] = useState("");

  // const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  //fab icon
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const [handleLossModal, sethandleLossModal] = useState(false);
  const [CouplorIDForSaveLossData, setCouplorIDForSaveLossData] = useState(0);
  const [handleLossModalForPLCS, sethandleLossModalForPLCS] = useState(false);
  const [PLCSIDForSaveLossData, setPLCSIDForSaveLossData] = useState(0);
  const { fitView } = useReactFlow();
  const [reactflowInstance, setReactflowInstance] = useState(null);
  useEffect(() => {
    if (location !== undefined) {
      console.log(location);
      console.log(location.state.mapapiKey);
      setdeviceName(location.state.mapapiKey);
      setMapKey(location.state.mapapiKey);
      getMasteData();
      getPolylineDataMaster();
    }
  }, []);

  useEffect(() => {
    if (reactflowInstance && nodes.length > 0) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, nodes.length]);

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        console.log("flow loaded:", rfi);
      }
    },
    [reactflowInstance]
  );
  const [deviceData, setdeviceData] = useState(null);
  const [PoliLineData, setPoliLineData] = useState(null);
  const [mapDataFinal, setmapDataFinal] = useState(null);
  const [CouplerShow, setCouplerShow] = useState(false);
  const [FTTXShow, setFttxShow] = useState(false);

  const handleFTTXShow = () => {
    setFttxShow(!FTTXShow);
  };
  const handleCouplerShow = () => {
    setCouplerShow(!CouplerShow);
  };
  const [JointShow, setJointShow] = useState(false);
  const handleJointShow = () => {
    setJointShow(!JointShow);
  };
  const [PlcShow, setPlcShow] = useState(false);
  const handlePLCShow = () => {
    setPlcShow(!PlcShow);
  };
  const [LoopShow, setLoopShow] = useState(false);
  const handleLoopShow = () => {
    setLoopShow(!LoopShow);
  };
  const [WDMShow, setWDMShow] = useState(false);
  const handleWDMShow = () => {
    setWDMShow(!WDMShow);
  };
  const [WDMShowPassive, setWDMShowPassive] = useState(false);
  const handleWDMShowPassive = () => {
    setWDMShowPassive(!WDMShowPassive);
  };
  const [ONUShow, setONUShow] = useState(false);
  const handleONUShow = () => {
    setONUShow(!ONUShow);
  };
  const [ORShow, setORShow] = useState(false);
  const handleORShow = () => {
    setORShow(!ORShow);
  };
  const [ORPShow, setORPShow] = useState(false);
  const handleORPShow = () => {
    setORPShow(!ORPShow);
  };
  const [PolyLineShow, setPolyLineShow] = useState(false);
  const handlePolyLineShow = () => {
    setPolyLineShow(!PolyLineShow);
  };

  function FTTxWidget() {
    return (
      <CModal show={FTTXShow} size="sm" onClose={handleFTTXShow}>
        {location.state.data !== null ? (
          FTTXShow ? (
            <CustomeFTTxshow
              data={location.state.data}
              MapKey={MapKey}
              DeleteDataByID={() => DeleteDataByID(deviceData.id)}
              getMasteData={() => getMasteData()}
              handleImageView={() =>
                handleImageView(
                  deviceData.images
                )
              }
              forftth={true}
              OncloseModal={() => {
                handleFTTXShow();
              }}
              match={match}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function CouplerWidget() {
    return (
      <CModal show={CouplerShow} size="sm" onClose={handleCouplerShow}>
        {deviceData !== null ? (
          CouplerShow ? (
            <CustomeCouplerShow
              data={deviceData}
              MapKey={MapKey}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleCouplerShow();
              }}
              getMasteData={() => {
                console.log("zdsfdfssfsfsdfdf");
                getMasteData();
                // if(handleLossModal)
                // {
                //   sethandleLossModal(!handleLossModal);
                // }
                handleCouplerShow();
              }}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
              sethandleLossModal={() => sethandleLossModal(!handleLossModal)}
              setCouplorIDForSaveLossData={() =>
                setCouplorIDForSaveLossData(deviceData.id)
              }
              forftth={true}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function LoopWidget() {
    return (
      <CModal show={LoopShow} onClose={handleLoopShow} size="sm">
        {deviceData !== null ? (
          LoopShow ? (
            <CustomeLoopsShow
              data={deviceData}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleLoopShow();
              }}
              MapKey={MapKey}
              getMasteData={() => {
                getMasteData();
                handleLoopShow();
              }} // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
              forftth={true}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function JointWidget() {
    return (
      <CModal show={JointShow} size="sm" onClose={handleJointShow}>
        {deviceData !== null ? (
          JointShow ? (
            <CustomeJointsShow
              data={deviceData}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleJointShow();
              }}
              MapKey={MapKey}
              getMasteData={() => {
                getMasteData();
                handleJointShow();
              }}
              // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              forftth={true}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function WDMWidget() {
    return (
      <CModal show={WDMShow} onClose={handleWDMShow} size="sm">
        {deviceData !== null ? (
          WDMShow ? (
            <CustomeWDMShow
              data={deviceData}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleWDMShow();
              }}
              MapKey={MapKey}
              getMasteData={() => {
                getMasteData();
                handleWDMShow();
              }}
              handleImageView={() => handleImageView(deviceData.images)}
              forftth={true}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function WDMPWidget() {
    return (
      <CModal show={WDMShowPassive} onClose={handleWDMShowPassive} size="sm">
        {deviceData !== null ? (
          WDMShowPassive ? (
            <CustomeWDMShow
              data={deviceData}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleWDMShowPassive();
              }}
              MapKey={MapKey}
              getMasteData={() => {
                getMasteData();
                handleWDMShowPassive();
              }}
              handleImageView={() => handleImageView(deviceData.images)}
              forftth={true}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function ORWidget() {
    return (
      <CModal show={ORShow} size="sm" onClose={handleORShow}>
        {" "}
        {deviceData !== null ? (
          ORShow ? (
            <CustomeORShow
              data={deviceData}
              type="ora"
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleORShow();
              }}
              MapKey={MapKey}
              forftth={true}
              getMasteData={() => {
                getMasteData();
                handleORShow();
              }}
              // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function ORPWidget() {
    return (
      <CModal size="sm" show={ORPShow} onClose={handleORPShow}>
        {deviceData !== null ? (
          ORPShow ? (
            <CustomeORShow
              data={deviceData}
              type="orp"
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleORPShow();
              }}
              MapKey={MapKey}
              forftth={true}
              getMasteData={() => {
                getMasteData();
                handleORPShow();
              }}
              // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function ONUWidget() {
    return (
      <CModal show={ONUShow} size="sm" onClose={handleONUShow}>
        {deviceData !== null ? (
          ONUShow ? (
            <CustomeONUShow
              data={deviceData}
              MapKey={MapKey}
              forftth={true}
              DeleteDataByID={() => {
                DeleteDataByID(deviceData.id);
                handleONUShow();
              }}
              getMasteData={() => {
                getMasteData();
                handleONUShow();
              }}
              // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function PLCWidget() {
    return (
      <CModal show={PlcShow} size="sm" onClose={handlePLCShow}>
        {deviceData !== null ? (
          PlcShow ? (
            <CustomePLCSShow
              data={deviceData}
              MapKey={MapKey}
              forftth={true}
              getMasteData={() => {
                getMasteData();
                handlePLCShow();
              }}
              // getMasteData={() => getMasteData()}
              handleImageView={() => handleImageView(deviceData.images)}
              mapDataFinal={mapDataFinal}
              sethandleLossModalForPLCS={() =>
                sethandleLossModalForPLCS(!handleLossModalForPLCS)
              }
              setPLCSIDForSaveLossData={() =>
                setPLCSIDForSaveLossData(deviceData.id)
              }
              DeleteDataByID={() => DeleteDataByID(deviceData.id)}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CModal>
    );
  }
  function PolyLineWidget() {
    return (
      <CModal show={PolyLineShow} size="sm" onClose={handlePolyLineShow}>
        {PoliLineData !== null ? (
          <PolilineEditForFTTx
            DeleteDataByID={() => {
              DeleteDataByID(PoliLineData.id);
              setPolyLineShow(!PolyLineShow);
            }}
            MapKey={MapKey}
            data={PoliLineData}
            getMasteData={() => {
              getPolylineDataMaster();
              getMasteData();
              setPolyLineShow(!PolyLineShow);
            }}
          />
        ) : (
          ""
        )}
      </CModal>
    );
  }
  const [imgURL, setimgURL] = useState("");
  const [handleImageModal, sethandleImageModal] = useState(false);

  const handleImageView = (img) => {
    setimgURL(img);
    sethandleImageModal(!handleImageModal);
  };

  // APIS
  function AddData(type) {
    console.log(type);
    const newNode = {
      id: getNodeId(),
      data: { label: type },
      position: {
        x: 0,
        y: 100,
      },
    };
    // setNodes((nds) => nds.concat(newNode));

    var data = {
      latlang: {
        lat: 0,
        lang: 100,
      },
      // point_id: PoliId,
      type: type,
      data: {
        icon_id: type === "onu" ? 1 : null,
      },
      core_swap: type == "WDM_passive" ? 1 : 0,
      meters: "0",
      master_id: mapDataFinal !== null ? mapDataFinal.id : 0,
      loss_id: 0,
      fttx_id: location.state.data.id,
      is_fttx: true,
      name: type,
      icon_type: type,
      icon_name: "this",
    };
    console.log(data);
    console.log(mapDataFinal);

    MapApi.addMasterFromDraw(data, deviceName)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setCheckLoop(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getPolylineDataMaster = () => {
    setEdges([]);
    var edgess = [];
    // console.log(edgess);
    if (location.state !== undefined)
      MapApi.getMapAllFttxData(
        location.state.portnumber,
        MapKey,
        location.state.data.id
      ).then((response) => {
        edgess.length = 0;
        edgess = [];
        if (response.data.data !== undefined && response.data.data !== null) {
          setmapDataFinal(response.data.data);
        }
        if (response.data.data.polylines !== undefined) {
          setEdges(
            response.data.data.polylines.map((OBJData) => ({
              id: OBJData.id + "",
              source: OBJData.latlang[0]["lat"] + "",
              sourceHandle:
                OBJData.latlang[1] != null
                  ? OBJData.latlang[1]["lat"] + ""
                  : "",
              data: {
                otherdata: OBJData,
              },
              target: OBJData.latlang[0]["lang"] + "",
              targetHandle:
                OBJData.latlang[1] != null
                  ? OBJData.latlang[1]["lang"] + ""
                  : "",
              style: {
                strokeWidth: OBJData.polylinedata.line_width,
                stroke: OBJData.color,
              },
            }))
          );
          fitView();

          // setEdges((nds) => nds.concat(newNodes));

          // setEdges(newNodes);
        }
        // getMasteData();
        // setEdges((nds) => nds.concat([
        //     {
        //         "id": "260",
        //         "source": "255",
        //         // "sourceHandle":"a",
        //         // "selected": false,
        //         "data": {
        //             "id": 260,
        //             "type": "polyline",
        //             "name": "50",
        //             "loss_1490NM": "5.45",
        //             "loss_1550NM": "5.47",
        //             "meters": "0",
        //             "color": "#0066AA",
        //             "point_id": 258,
        //             "status": "Disable",
        //             "master_id": 225,
        //             "latlang": [
        //                 {
        //                     "id": 294,
        //                     "lat": "255",
        //                     "lang": "259",
        //                     "polyline_id": 260,
        //                     "status": 1,
        //                     "created_at": "2024-04-11T12:08:40.000000Z",
        //                     "updated_at": "2024-04-11T12:08:40.000000Z",
        //                     "deleted_at": null
        //                 }
        //             ],
        //             "enable": true,
        //             "polylinedata": {
        //                 "id": 103,
        //                 "optical_fiber_company": null,
        //                 "fiber_core": "1F",
        //                 "cable_type": "FTTH",
        //                 "number_of_tube": null,
        //                 "core_color": null,
        //                 "line_width": 5,
        //                 "edfa_id": 260,
        //                 "created_at": null,
        //                 "updated_at": null
        //             },
        //             "tube_data": [],
        //             "is_fttx": true
        //         },
        //         "target": "259",
        //         "targetHandle": "a",
        //         "style": {
        //             "strokeWidth": 5,
        //             "stroke": "#0066AA"
        //         }
        //     },
        //     {
        //         "id": "262",
        //         "source": "255",
        //         // "sourceHandle":"b",

        //         // "selected": false,
        //         "data": {
        //             "id": 262,
        //             "type": "polyline",
        //             "name": "50",
        //             "loss_1490NM": "5.45",
        //             "loss_1550NM": "5.47",
        //             "meters": "0",
        //             "color": "#0066AA",
        //             "point_id": 257,
        //             "status": "Disable",
        //             "master_id": 225,
        //             "latlang": [
        //                 {
        //                     "id": 296,
        //                     "lat": "255",
        //                     "lang": "261",
        //                     "polyline_id": 262,
        //                     "status": 1,
        //                     "created_at": "2024-04-11T12:08:55.000000Z",
        //                     "updated_at": "2024-04-11T12:08:55.000000Z",
        //                     "deleted_at": null
        //                 }
        //             ],
        //             "enable": true,
        //             "polylinedata": {
        //                 "id": 104,
        //                 "optical_fiber_company": null,
        //                 "fiber_core": "1F",
        //                 "cable_type": "FTTH",
        //                 "number_of_tube": null,
        //                 "core_color": null,
        //                 "line_width": 5,
        //                 "edfa_id": 262,
        //                 "created_at": null,
        //                 "updated_at": null
        //             },
        //             "tube_data": [],
        //             "is_fttx": true
        //         },
        //         "target": "261",
        //         "targetHandle": "b",
        //         "style": {
        //             "strokeWidth": 5,
        //             "stroke": "#0066AA"
        //         }
        //     }
        //     ]));
        // console.log(edgess);
      });
  };
  const [WDMStatus, setWDMStatus] = useState(false);
  const [actions, setactions] = useState([]);

  const getMasteData = () => {
    setNodes([]);
    // setEdges([]);
    var nodess = [];
    var edges = [];
    if (location.state !== undefined)
      MapApi.getMapAllFttxData(
        location.state.portnumber,
        MapKey,
        location.state.data.id
      )
        .then((response) => {
          console.log("Hello, World!");
          if (response.data.data !== undefined && response.data.data !== null) {
            setmapDataFinal(response.data.data);
          }
          console.log(response.data.data);
          if (response.data.data.port !== undefined) {
            if (response.data.data.port.wdm_status === 0) {
              setWDMStatus(false);
              setactions([
                {
                  icon: "C",
                  name: "Coupler",
                  onClick: () => AddData("coupler"),
                },
                {
                  icon: "P",
                  name: "PLC Splitter",
                  onClick: () => AddData("plc_splitter"),
                },
                { icon: "J", name: "Joint", onClick: () => AddData("joint") },
                {
                  icon: "L",
                  name: "Loop",
                  onClick: () => AddData("loop"),
                },

                {
                  icon: "OR",
                  name: "Optical Receiver",
                  onClick: () => AddData("optical_reciver"),
                },
                {
                  icon: "OR-P",
                  name: "Optical Receiver Passive",
                  onClick: () => AddData("optical_reciver_passive"),
                },
              ]);
            } else {
              setWDMStatus(true);

              setactions([
                {
                  icon: "O",
                  name: "ONU",
                  onClick: () => AddData("onu"),
                },
                {
                  icon: "C",
                  name: "Coupler",
                  onClick: () => {
                    AddData("coupler");
                  },
                },
                {
                  icon: "P",
                  name: "PLC Splitter",
                  onClick: () => AddData("plc_splitter"),
                },
                { icon: "J", name: "Joint", onClick: () => AddData("joint") },
                {
                  icon: "L",
                  name: "Loop",
                  onClick: () => {
                    AddData("loop");
                  },
                },
                {
                  icon: "ORN",
                  name: "Optical reciver node",
                  onClick: () => AddData("orn"),
                },
                {
                  icon: "OR",
                  name: "Optical Receiver",
                  onClick: () => AddData("optical_reciver"),
                },
                {
                  icon: "OR-P",
                  name: "Optical Receiver Passive",
                  onClick: () => AddData("optical_reciver_passive"),
                },
              ]);
            }
          }
          nodess.length = 0;
          nodess = [];
          if (location.state.data !== null) {
            console.log(location.state);
            nodess.push({
              id: location.state.id + "",
              type: "input",
              sourcePosition: "right",
              style: {
                backgroundImage: `url(${FTTxIcon})`,
                backgroundColor: "#D1B100",
                fontWeight: "bold",
                height: 35,
                width: 70,
                backgroundSize: "contain",
                backgroundRepeat: "repeat",
              },
              positionAbsolute: {
                x: 200,
                y: 100,
              },
              height: 150,
              width: 40,
              data: {
                otherdata: location.state.data,
              },
              position: {
                x: 10,
                y: 10,
              },
            });
          }
          if (response.data.data.loop !== undefined) {
            response.data.data.loop.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                style: {
                  backgroundImage: `url(${loopIcon})`,
                  backgroundColor: "#D1B100",
                  fontWeight: "bold",
                  height: 35,
                  width: 70,
                  backgroundSize: "contain",
                  backgroundRepeat: "repeat",
                },
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                height: 150,
                width: 40,
                data: {
                  label: "",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.optical_reciver !== undefined) {
            response.data.data.optical_reciver.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",

                targetPosition: "top",
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                style: {
                  fontSize: 18,
                  paddingTop: 3,
                  paddingBottom: 0,
                  height: 35,
                  width: 70,
                  fontWeight: "bold",
                  borderRadius: 3,

                  backgroundColor: "#00A65A",
                  borderWidth: 1,
                  borderColor: "#00A65A",
                  color: "#fff",
                },
                sourcePosition: "top",
                type: "ornode",
                data: {
                  label: "OR",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.optical_reciver_passive !== undefined) {
            response.data.data.optical_reciver_passive.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                height: 150,
                width: 40,
                targetPosition: "top",
                sourcePosition: "none",
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                style: {
                  fontSize: 18,
                  paddingTop: 3,
                  paddingBottom: 0,
                  borderRadius: 3,
                  height: 35,
                  width: 70,
                  fontWeight: "bold",
                  backgroundColor: "#00A65A",
                  borderWidth: 1,
                  borderColor: "#00A65A",
                  color: "#fff",
                },
                type: "orpnode",
                data: {
                  label: "ORP",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.plc_splitter !== undefined) {
            response.data.data.plc_splitter.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                style: {
                  fontSize: 15,
                  paddingTop: 3,
                  height: 35,
                  width: 70,
                  borderColor: "#31FF77",
                  fontWeight: "bold",
                  borderWidth: 3,
                },
                type: "plcnode",
                width: 40,
                data: {
                  label: OBJData.max !== null ? "1X" + OBJData.max.name : "1X0",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.joint !== undefined) {
            response.data.data.joint.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                data: {
                  label: "J",
                  otherdata: OBJData,
                },
                style: {
                  fontSize: 18,
                  paddingTop: 3,
                  paddingBottom: 10,
                  height: 35,
                  width: 70,
                  borderColor: "#fff",
                  backgroundColor: "#000000",
                  fontWeight: "bold",
                  borderWidth: 1,
                  color: "#fff",
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.coupler !== undefined) {
            response.data.data.coupler.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                height: 150,
                width: 40,

                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                style: {
                  fontSize: 15,
                  paddingTop: 3,
                  height: 35,
                  width: 70,
                  borderColor: "#31FF77",
                  fontWeight: "bold",
                  borderWidth: 3,
                },
                type: "couplernode",
                data: {
                  label:
                    OBJData.loss !== null
                      ? OBJData.loss.high_name + "|" + OBJData.loss.low_name
                      : "0   |   0",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.WDM !== undefined) {
            response.data.data.WDM.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                height: 150,
                width: 40,
                type: "wdmnode",
                style: {
                  fontSize: 18,
                  paddingTop: 3,
                  paddingBottom: 0,
                  borderRadius: 3,
                  height: 35,
                  width: 70,
                  fontWeight: "bold",
                  backgroundColor: "#0016A6",
                  borderWidth: 1,
                  borderColor: "#207784",
                  color: "#fff",
                },
                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                data: {
                  label: "WDM",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.WDM_passive !== undefined) {
            response.data.data.WDM_passive.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                height: 150,
                width: 40,
                type: "wdmpnode",
                style: {
                  fontSize: 18,
                  paddingTop: 3,
                  paddingBottom: 0,
                  borderRadius: 3,
                  height: 35,
                  width: 70,
                  fontWeight: "bold",
                  backgroundColor: "#0016A6",
                  borderWidth: 1,
                  borderColor: "#207784",
                  color: "#fff",
                },
                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                data: {
                  label: "WDM_passive",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          if (response.data.data.onu !== undefined) {
            response.data.data.onu.map((OBJData) => {
              nodess.push({
                id: OBJData.id + "",
                height: 150,
                type: "onunode",
                dragging: true,
                positionAbsolute: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
                style: {
                  fontSize: 18,
                  height: 35,
                  width: 70,
                  paddingTop: 2,
                  backgroundColor: "#207784",
                  borderWidth: 3,
                  borderRadius: 3,
                  
                  borderColor: "#207784",
                  color: "#fff",
                },
                width: 40,
                data: {
                  label: "ONU",
                  otherdata: OBJData,
                },
                position: {
                  x: parseFloat(OBJData.latlang.lat),
                  y: parseFloat(OBJData.latlang.lang),
                },
              });
            });
          }
          setEdges((nds) => nds.concat(edges));
          setNodes((nds) => nds.concat(nodess));

          setTimeout(() => {
            console.log(edges);
          }, 500);
          setTimeout(() => {
            console.log(nodess);
          }, 8000);
        })
        .catch((error) => {});
    fitView();
  };

  //Node
  const onNodeClick = (event, node) => {
    console.log("click node", node.data);
    setdeviceData(node.data.otherdata);
    if (node.data.otherdata.type === "coupler") {
      if (deviceData !== null) {
        handleCouplerShow();
      }
    } else if (node.data.otherdata.type === "joint") {
      handleJointShow();
    } else if (node.data.otherdata.type === "plc_splitter") {
      handlePLCShow();
    } else if (node.data.otherdata.type === "loop") {
      if (deviceData !== null) {
        handleLoopShow();
      }
    } 
    // else if (node.data.label === "WDM") {
    //   handleWDMShow();
    // }
     else if (node.data.label === "WDM_passive" || node.data.label === "WDM") {
      handleWDMShowPassive();
    } else if (node.data.label === "ONU") {
      handleONUShow();
    } else if (node.data.label === "OR") {
      handleORShow();
    } else if (node.data.label === "ORP") {
      handleORPShow();
    } else if (node.data.otherdata.type === "FTTx") {
      handleFTTXShow();
    } else {
      console.log("click node", node.data.label);
    }
  };
  const onNodeChanges = (event, node) => {
    setNodes((nds) => nds.concat(node));
    console.log(nodes);
    console.log(edges);
    var data = {
      latlang: {
        lat: node.position.x,
        lang: node.position.y,
      },
    };
    console.log(data);
    if (node.data.otherdata.type !== "FTTx")
      MapApi.updateLatLgnByIdForDeviceType(node.id, data, MapKey)
        .then((rsponse) => {
          console.log(rsponse.data);
          //   getMasteData();
        })
        .catch((error) => {
          console.log(error);
        });
    console.log("click node", node.data);
  };

  //Edge
  const onConnect = (params) => {
    console.log(params);
    // setEdges((eds) => addEdge(params, eds));
    var data = {
      master_id: mapDataFinal !== null ? mapDataFinal.id : 0,
      type: "polyline",
      color: "#0066AA",
      is_fttx: true,
      // point_id: params.source,
      fttx_id: location.state.data.id,

      point_id:
        params.sourceHandle !== null ? params.sourceHandle : params.source,
      meters: 0,
      data: {
        line_width: "5",
        cable_type: "FTTH",
        fiber_core: "1F",
      },
    };

    console.log(data);
    // console.log(data);
    MapApi.addMaster(data, MapKey)
      .then((response) => {
        // console.log(response.data);
        // map.current.leafletElement.closePopup();
        MapApi.addPolilineLatLang(
          {
            polyline_id: response.data.data.id,
            lat: params.source,
            lang: params.target,
          },
          MapKey
        )
          .then((responsea) => {
            AddPointIdforCoupler(params.target, response.data.data.id);
            MapApi.addPolilineLatLang(
              {
                polyline_id: response.data.data.id,
                lat: params.sourceHandle !== null ? params.sourceHandle : 0,
                lang: params.targetHandle !== null ? params.targetHandle : 0,
              },
              MapKey
            )
              .then((responsea) => {})
              .catch((error) => {
                //console.log(error);
              });
          })
          .catch((error) => {
            //console.log(error);
          });
      })
      .catch((error) => {
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
        // console.log(error);
      });
  };
  const onEdgeClick = (event, edge) => {
    console.log("click edge", edge);
    setPoliLineData(edge.data.otherdata);
    // setPoliLineData();
    handlePolyLineShow();
  };
  function AddPointIdforCoupler(id, PointId) {
    // console.log({
    //     id: id,
    //     point_id: PointId
    // })
    var data = {
      point_id: PointId,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getMasteData();
        getPolylineDataMaster();
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  function DeleteDataByID(id) {
    MapApi.DeleteMasterDataById(id, MapKey)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getMasteData();
        getPolylineDataMaster();
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <>
      <div className="headerformobile">
        <TheHeaderPlanScreen
          mapFunctionallity={true}
          mapdevicename={deviceName}
          title={location.state !== undefined ? location.state.data.name : ""}
          drawNav={true}
          isfttx={true}
        />
      </div>
      <MapsidePanel
        couplorfun={() => {
          AddData("coupler");
        }}
        jointfun={() => {
          AddData("joint");
        }}
        loopfun={() => {
          AddData("loop");
        }}
        orfun={() => {
          AddData("optical_reciver");
        }}
        orpfun={() => {
          AddData("optical_reciver_passive");
        }}
        plcfun={() => {
          AddData("plc_splitter");
        }}
        onufun={() => {
          AddData("onu");
        }}
        ornfun={() => {
          AddData("WDM");
        }}
        ornpfun={() => {
          AddData("WDM_passive");
        }}
        cs={true}
        fttx={false}
        joints={true}
        ls={true}
        ors={MapKey === "epon_olt" || MapKey === "gpon_olt" ?false :true}
        onu={ WDMStatus}
        orn={MapKey === "epon_olt" || MapKey === "gpon_olt" ?false :true}
        ornp={MapKey === "epon_olt" || MapKey === "gpon_olt" ?false :true}
        orps={MapKey === "epon_olt" || MapKey === "gpon_olt" ?false :true}
        ps={true}
      />
      <div className="speedDialcomponent">
        <SpeedDial
          ariaLabel="Add"
          style={{ margin: 0, right: 20, bottom: 20, position: "fixed" }}
          hidden={false}
          icon={<SpeedDialIcon openIcon={<AddLocation />} />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              style={{ backgroundColor: "#000000" }}
              color={"#000000"}
              icon={action.icon}
              onClick={action.onClick}
              tooltipTitle={action.name}
              href={action.link}
            />
          ))}
        </SpeedDial>
      </div>
      <div style={{ height: "80vh" }}>
        <br />
        <br />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          // onNodeDrag={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={onNodeChanges}
          // onEdgesChange={onEdgesChange}
          onLoad={onLoad}
          onConnect={onConnect}
          onNodeClick={onNodeClick}
          onEdgeClick={onEdgeClick}
          fitView
          nodeTypes={nodeTypes}
        >
          <Controls />
          <MiniMap />
          <Background />
        </ReactFlow>

        <FTTxWidget />
        <CouplerWidget />
        <LoopWidget />
        <ORPWidget />
        <ORWidget />
        <WDMWidget />
        <WDMPWidget />
        <JointWidget />
        <PLCWidget />
        <ONUWidget />
        <PolyLineWidget />
        <PLCLossChart
          CouplorIDForSaveLossData={PLCSIDForSaveLossData}
          show={handleLossModalForPLCS}
          onClose={() => sethandleLossModalForPLCS(!handleLossModalForPLCS)}
          onupdateData={() => getMasteData()}
          MapKey={MapKey}
        />
        <LossChart
          CouplorIDForSaveLossData={CouplorIDForSaveLossData}
          show={handleLossModal}
          onClose={() => sethandleLossModal(!handleLossModal)}
          onupdateData={() => {
            getMasteData();
            handleCouplerShow();
          }}
          MapKey={MapKey}
        />
        <ImageModal
          show={handleImageModal}
          img={imgURL}
          onClose={() => sethandleImageModal(!handleImageModal)}
        />
      </div>
    </>
  );
};
const WrappedFlowComponent = () => (
  <ReactFlowProvider>
    <FTTxDraw />
  </ReactFlowProvider>
);
export default WrappedFlowComponent;
