import {
  CButton,
  CCol,
  CCard,
  CRow,
  CCollapse,
  CCardBody,
  CNav,
  CNavItem,
  CTabPane,
  CTooltip,
  CCardHeader,
  CNavLink,
  CTabContent,
  CModal,
  CModalBody,
  CTabs,
  CModalHeader,
} from "@coreui/react";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import L from "leaflet";
import { MapApi } from "src/Config/API";
import swal from "sweetalert";

const AddDevicesModalFromFiberLine = ({
  data,
  MapKey,
  getMasteData,
  setcheckPolyLines,
  map,
  wdmStatus,
  mapDataFinal,
  CLossSpliceJoint,
  show,
  onClose,
}) => {

  function PostPolilineContinueByLatLangCoupler(lat, lng, PointId, PolyData) {
    console.log({
      polyline_id: PolyData.id,
      lat: lat,
      lang: lng,
      point_id: PointId,
    });
    MapApi.addPolilineLatLang(
      {
        polyline_id: PolyData.id,
        lat: lat,
        lang: lng,
      },
      MapKey
    )
      .then((response) => {
        AddPointIdforCoupler(PointId, PolyData.id);
        getMasteData();
        // setcheckPolyLinesContinue();
        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPointIdforCoupler(id, PointId) {
    var data = {
      point_id: PointId,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getDistance();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddCouplorWhenLineDrawing(data, latlang) {
    var datas = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "coupler",
      master_id: data.master_id,
      // loss_id: 1,
      point_id: data.id,
      name: "Coupler",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(datas, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
        // setcheckPolyLines();
        // onClose();
        // getMasteData();
        // setEnableCoupler(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddJointWhenLineDrawing(data, latlang) {
    var data = {
      type: "joint",
      latlang: {
        polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      point_id: data.id,
      master_id: data.master_id,
      meters: CLossSpliceJoint[0].high_value,
      loss_id: 1,
      name: "Joint",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddLoopsWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "loop",
      meters: "0",
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "loop",
      icon_type: "loop",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines();
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddORWhenLineDrawing(data, latlang, type) {
    var data = {
      // customer: {
      //   name: "w",
      //   phone: 898565232,
      //   address: "ewqewq",
      //   setupbox_company: "ewqw",
      // },
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: type,
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "Optical reciver",
      // op_power: 0,
      meters: 0,
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPLCSWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "plc_splitter",
      master_id: data.master_id,
      loss_id: 1,
      point_id: data.id,
      name: "plc splitter",
      icon_type: "plc splitter",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddORNWhenLineDrawing(data, latlang,type) {
    var dataValue = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: type,
      meters: "1",
      core_swap: type=="WDM"?"0":"1",
      point_id: data.id,
      master_id: data.master_id,
      // loss_id: 1,
      
      name: type,
      icon_type: type,
      icon_name: type,
    };
    MapApi.addMaster(dataValue, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddONUWhenLineDrawing(data, latlang) {
    var data = {
      type: "onu",
      latlang: {
        polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      point_id: data.id,
      master_id: data.master_id,
      meters: CLossSpliceJoint[0].high_value,
      loss_id: 1,
      name: "onu",
      data: {},
      icon_type: "onu",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        swal({
          title: "Warning!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
        console.log(error);
      });
  }
  function AddFTTxWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "FTTx",
      meters: "1",
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "FTTx",
      icon_type: "FTTx",
      icon_name: "FTTx",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getDistance(PoliId) {
    var PolyData = [];
    MapApi.getMasterDataById(PoliId, MapKey)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.latlang !== []) {
          console.log(response.data.data.latlang);
          var len = response.data.data.latlang.length,
            newData = { aaData: [] },
            i;

          for (i = 0; i < len; i += 1) {
            newData.aaData.push([
              response.data.data.latlang[i].lat,
              response.data.data.latlang[i].lang,
              {
                id: response.data.data.latlang[i].id,
                // color: PolyData.color,
              },
            ]);
          }

          if (map !== null) {
            console.log("data");
            var polyline = L.polyline(newData.aaData, {
              color: "#00FFFFFF",
              weight: 0,
            }).addTo(map);
            var previousPoint;
            console.log(newData.aaData);
            var finalMeters = 0;
            //leafletjs.com/reference.html#polyline-getlatlngs
            var polygon = polyline.getLatLngs().forEach(function (latLng) {
              if (previousPoint) {
                finalMeters += previousPoint.distanceTo(latLng);
                // L.marker(latLng)
                //   .bindPopup(
                //     "Distance from previous point: " +
                //       previousPoint.distanceTo(latLng).toFixed(2) + // http://leafletjs.com/reference.html#latlng-distanceto
                //   `    " meter(s)"
                //   )
                //   .addTo(map);
              }
              previousPoint = latLng;
            });
            var MeterDetails = {
              meters: finalMeters,
            };
            MapApi.UpdateMasterDataById(PoliId, MeterDetails, MapKey)
              .then((response) => {
                getMasteData();
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
            console.log(finalMeters);
          }
        }
        // Object.values(response.data.data.latlang).map((datata) => {
        //   console.log(datata);

        //   // PolyData.push(datata);
        // });
      })
      .catch((error) => {});
  }
  var LatLngData = [];
  data.latlang.map((data) => {
    LatLngData.push(data);
  });

  return (
    <div>
      <CCard style={{ width: "100%" }}>
        <CCardHeader
          style={{
            backgroundColor: "#0090FF",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => onClose(!show)}
        >
          Show Devices
        </CCardHeader>

        <CCollapse show={show}>
          <CTabs activeTab="Coulper">
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink data-tab="Coulper">
                  C{/* <CTooltip content={"sdaa"}>C</CTooltip> */}
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="Joint">J</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="Loops">L</CNavLink>
              </CNavItem>
              {MapKey ==="gpon_olt" || MapKey ==="epon_olt"? <></> : <>
              <CNavItem>
                <CNavLink data-tab="Optical-receiver">OR</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="optical-receiver-passive">ORP</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="WDM">WDM</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="WDMP">WDMP</CNavLink>
              </CNavItem>
              </>}
            
            
              <CNavItem>
                <CNavLink data-tab="PLC">PLC</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="ONU">ONU</CNavLink>
              </CNavItem>
            
              <CNavItem>
                <CNavLink data-tab="FTTx">FTTx</CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane data-tab="Coulper">
                <CCardBody>
                  <CTooltip content={"Add Coupler"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddCouplorWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1]
                        )
                      }
                    />
                  </CTooltip>
                  <CRow>
                    {mapDataFinal.coupler.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            color="info"
                            style={{
                              margin: 1,
                              borderRadius: 15,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="Joint">
                <CCardBody>
                  <CTooltip content={"Add Joint"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddJointWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1]
                        )
                      }
                    />
                  </CTooltip>
                  <CRow>
                    <CCol>
                      <CRow>
                        <CCol>
                          <CRow>
                            {mapDataFinal.joint.map((Cdata, i) => {
                              return (
                                <div>
                                  <CButton
                                    onClick={() =>
                                      PostPolilineContinueByLatLangCoupler(
                                        Cdata.latlang.lat,
                                        Cdata.latlang.lang,
                                        Cdata.id,
                                        data
                                      )
                                    }
                                    style={{
                                      backgroundColor: "black",
                                      color: "white",
                                      margin: 2,
                                      borderRadius: 15,
                                    }}
                                  >
                                    {Cdata.name}
                                  </CButton>
                                </div>
                              );
                            })}
                          </CRow>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="Loops">
                <CCardBody>
                  <CTooltip content={"Add Loops"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddLoopsWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1]
                        )
                      }
                    />
                  </CTooltip>
                  <CRow>
                    <CCol>
                      <CRow>
                        <CCol>
                          <CRow>
                            <CCol>
                              <CRow>
                                <CCol></CCol>
                              </CRow>
                              <CRow style={{ marginLeft: 1 }}>
                                {mapDataFinal.loop.map((Cdata, i) => {
                                  return (
                                    <div>
                                      <CButton
                                        onClick={() =>
                                          PostPolilineContinueByLatLangCoupler(
                                            Cdata.latlang.lat,
                                            Cdata.latlang.lang,
                                            Cdata.id,
                                            data
                                          )
                                        }
                                        style={{
                                          backgroundColor: "#D1B100",
                                          color: "black",
                                          borderRadius: 15,
                                          margin: 2,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    </div>
                                  );
                                })}
                              </CRow>
                            </CCol>
                          </CRow>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="Optical-receiver">
                <CCardBody>
                  <CTooltip content={"Add Optical Receiver"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddORWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "optical_reciver"
                        )
                      }
                    />
                  </CTooltip>{" "}
                  <CRow style={{ marginLeft: 1 }}>
                    {mapDataFinal.optical_reciver.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            style={{
                              backgroundColor: "#00A65A",
                              color: "white",
                              borderRadius: 15,
                              margin: 2,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="optical-receiver-passive">
                <CCardBody>
                  <CTooltip content={"Add Optical Receiver Passive"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddORWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "optical_reciver_passive"
                        )
                      }
                    />
                  </CTooltip>
                  <CRow style={{ marginLeft: 1 }}>
                    {mapDataFinal.optical_reciver_passive.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            style={{
                              backgroundColor: "#00A65A",
                              color: "white",
                              borderRadius: 15,
                              margin: 2,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="ONU">
                <CCardBody>
                  <CTooltip content={"ONU"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddONUWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "onu"
                        )
                      }
                    />
                  </CTooltip>
                  <CRow style={{ marginLeft: 1 }}>
                    {mapDataFinal.onu.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            style={{
                              backgroundColor: "#00A65A",
                              color: "white",
                              borderRadius: 15,
                              margin: 2,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                     <CTooltip content={"ONU"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 0,
                        marginTop:10,
                        marginLeft:10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddONUWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "onu"
                        )
                      }
                    />
                  
                  </CTooltip>
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="PLC">
                <CCardBody>
                  <CTooltip content={"PLC Splitter"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddPLCSWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "plc_splitter"
                        )
                      }
                    />
                  </CTooltip>
                  <CRow style={{ marginLeft: 1 }}>
                    {mapDataFinal.plc_splitter.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            style={{
                              backgroundColor: "#00A65A",
                              color: "white",
                              borderRadius: 15,
                              margin: 2,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                  </CRow>
                </CCardBody>
              </CTabPane>
              <CTabPane data-tab="WDM">
                {wdmStatus === true ? (
                  <CCardBody>
                    <CTooltip content={"WDM Node"}>
                      <Add
                        style={{
                          cursor: "pointer",
                          marginBottom: 10,
                          backgroundColor: "InfoBackground",
                        }}
                        onClick={() =>
                          AddORNWhenLineDrawing(
                            data,
                            LatLngData[LatLngData.length - 1],
                         
                            "WDM"
                          )
                        }
                      />
                    </CTooltip>
                    <CRow style={{ marginLeft: 1 }}>
                      {mapDataFinal.orn.map((Cdata, i) => {
                           if(Cdata.core_swap=="0")
                        return (
                          <div>
                            <CButton
                              onClick={() =>
                                PostPolilineContinueByLatLangCoupler(
                                  Cdata.latlang.lat,
                                  Cdata.latlang.lang,
                                  Cdata.id,
                                  data
                                )
                              }
                              style={{
                                backgroundColor: "#00A65A",
                                color: "white",
                                borderRadius: 15,
                                margin: 2,
                              }}
                            >
                              {Cdata.name}
                            </CButton>
                          </div>
                        );
                      })}
                    </CRow>
                  </CCardBody>
                ) : (
                  <div style={{ padding: 10 }}>
                    For WDM you have to Unable WDMStatus
                  </div>
                )}
              </CTabPane>
              <CTabPane data-tab="WDMP">
                {wdmStatus === true ? (
                  <CCardBody>
                    <CTooltip content={"WDM Passive Node"}>
                      <Add
                        style={{
                          cursor: "pointer",
                          marginBottom: 10,
                          backgroundColor: "InfoBackground",
                        }}
                        onClick={() =>
                          AddORNWhenLineDrawing(
                            data,
                            LatLngData[LatLngData.length - 1],
                         
                            "WDM_passive"
                          )
                        }
                      />
                    </CTooltip>
                    <CRow style={{ marginLeft: 1 }}>
                      {mapDataFinal.orn.map((Cdata, i) => {
                        if(Cdata.core_swap=="1")
                        return (
                          <div>
                            <CButton
                              onClick={() =>
                                PostPolilineContinueByLatLangCoupler(
                                  Cdata.latlang.lat,
                                  Cdata.latlang.lang,
                                  Cdata.id,
                                  data
                                )
                              }
                              style={{
                                backgroundColor: "#00A65A",
                                color: "white",
                                borderRadius: 15,
                                margin: 2,
                              }}
                            >
                              {Cdata.name}
                            </CButton>
                          </div>
                        );
                      })}
                    </CRow>
                  </CCardBody>
                ) : (
                  <div style={{ padding: 10 }}>
                    For WDM you have to Unable WDMStatus
                  </div>
                )}
              </CTabPane>

              <CTabPane data-tab="FTTx">
                <CCardBody>
                  <CTooltip content={"FTTx"}>
                    <Add
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: "InfoBackground",
                      }}
                      onClick={() =>
                        AddFTTxWhenLineDrawing(
                          data,
                          LatLngData[LatLngData.length - 1],
                          "FTTx"
                        )
                      }
                    />
                  </CTooltip>
                  <CRow style={{ marginLeft: 1 }}>
                    {mapDataFinal.FTTx.map((Cdata, i) => {
                      return (
                        <div>
                          <CButton
                            onClick={() =>
                              PostPolilineContinueByLatLangCoupler(
                                Cdata.latlang.lat,
                                Cdata.latlang.lang,
                                Cdata.id,
                                data
                              )
                            }
                            style={{
                              backgroundColor: "#00A65A",
                              color: "white",
                              borderRadius: 15,
                              margin: 2,
                            }}
                          >
                            {Cdata.name}
                          </CButton>
                        </div>
                      );
                    })}
                  </CRow>
                </CCardBody>
              </CTabPane>
            </CTabContent>
          </CTabs>
        </CCollapse>
      </CCard>
      {/* <CModal
        show={show}
        onClose={onClose}
        className="modal-centered"
        centered
        size="xl"
        closeOnBackdrop={false}
        fade
      >
        <CModalHeader closeButton>Add Devices</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#0090FF",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setCouplorCollapse(!Couplorcollapse)}
                >
                  Coupler
                </CCardHeader>
                <CCollapse show={Couplorcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          {mapDataFinal.coupler.map((Cdata, i) => {
                            return (
                              <div>
                                {Cdata.device_status === "Disable" ? (
                                  <CButton
                                    disabled
                                    color="info"
                                    style={{
                                      margin: 2,
                                      borderRadius: 15,
                                    }}
                                  >
                                    {Cdata.name}
                                  </CButton>
                                ) : (
                                  <CButton
                                    onClick={() =>
                                      PostPolilineContinueByLatLangCoupler(
                                        Cdata.latlang.lat,
                                        Cdata.latlang.lang,
                                        Cdata.id,
                                        data
                                      )
                                    }
                                    color="info"
                                    style={{
                                      margin: 2,
                                      borderRadius: 15,
                                    }}
                                  >
                                    {Cdata.name}
                                  </CButton>
                                )}
                              </div>
                            );
                          })}
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddCouplorWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Coupler
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#000000",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setJointCollapse(!Jointcollapse)}
                >
                  Joints
                </CCardHeader>
                <CCollapse show={Jointcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              {mapDataFinal.joint.map((Cdata, i) => {
                                return (
                                  <div>
                                    {Cdata.device_status === "Disable" ? (
                                      <CButton
                                        disabled
                                        color="info"
                                        style={{
                                          margin: 2,
                                          borderRadius: 15,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    ) : (
                                      <CButton
                                        onClick={() =>
                                          PostPolilineContinueByLatLangCoupler(
                                            Cdata.latlang.lat,
                                            Cdata.latlang.lang,
                                            Cdata.id,
                                            data
                                          )
                                        }
                                        style={{
                                          backgroundColor: "black",
                                          color: "white",
                                          margin: 2,
                                          borderRadius: 15,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    )}
                                  </div>
                                );
                              })}
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddJointWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Joint
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#D1B100",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setLoopCollapse(!Loopcollapse)}
                >
                  Loops
                </CCardHeader>
                <CCollapse show={Loopcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow>
                                  <CCol></CCol>
                                </CRow>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.loop.map((Cdata, i) => {
                                    return (
                                      <div>
                                        {Cdata.device_status === "Disable" ? (
                                          <CButton
                                            disabled
                                            color="info"
                                            style={{
                                              margin: 2,
                                              borderRadius: 15,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        ) : (
                                          <CButton
                                            onClick={() =>
                                              PostPolilineContinueByLatLangCoupler(
                                                Cdata.latlang.lat,
                                                Cdata.latlang.lang,
                                                Cdata.id,
                                                data
                                              )
                                            }
                                            style={{
                                              backgroundColor: "#D1B100",
                                              color: "black",
                                              borderRadius: 15,
                                              margin: 2,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        )}
                                      </div>
                                    );
                                  })}
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddLoopsWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Loop
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            {mapDataFinal.port.wdm_status === 1 ? (
              <>
                <CCol xs="12" sm="6" md="6">
                  <CCard>
                    <CCardHeader
                      style={{
                        backgroundColor: "#0016A6",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => setORNcollapse(!ORNcollapse)}
                    >
                      ORN
                    </CCardHeader>
                    <CCollapse show={ORNcollapse}>
                      <CCardBody>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow>
                                  <CCol>
                                    <CRow>
                                      <CCol></CCol>
                                    </CRow>
                                    <CRow style={{ marginLeft: 1 }}>
                                      {mapDataFinal.orn.map((Cdata, i) => {
                                        return (
                                          <div>
                                            {Cdata.device_status ===
                                            "Disable" ? (
                                              <CButton
                                                disabled
                                                color="info"
                                                style={{
                                                  margin: 2,
                                                  borderRadius: 15,
                                                }}
                                              >
                                                {Cdata.name}
                                              </CButton>
                                            ) : (
                                              <CButton
                                                onClick={() =>
                                                  PostPolilineContinueByLatLangCoupler(
                                                    Cdata.latlang.lat,
                                                    Cdata.latlang.lang,
                                                    Cdata.id,
                                                    data
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: "#0016A6",
                                                  color: "white",
                                                  borderRadius: 15,
                                                  margin: 2,
                                                }}
                                              >
                                                {Cdata.name}
                                              </CButton>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </CRow>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                        <br />
                        <CRow>
                          <CCol>
                            <CButton
                              block
                              style={{
                                backgroundColor: "#4833FF",
                                color: "white",
                              }}
                              onClick={() =>
                                AddORNWhenLineDrawing(
                                  data,
                                  LatLngData[LatLngData.length - 1]
                                )
                              }
                            >
                              Add ORN(Optical Receive Node)
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </CCol>
              </>
            ) : (
              <></>
            )}

            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#00A65A",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setOPcollapse(!OPcollapse)}
                >
                  Optical Receiver
                </CCardHeader>
                <CCollapse show={OPcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.optical_reciver.map(
                                    (Cdata, i) => {
                                      return (
                                        <div>
                                          {Cdata.device_status === "Disable" ? (
                                            <CButton
                                              disabled
                                              color="info"
                                              style={{
                                                margin: 2,
                                                borderRadius: 15,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          ) : (
                                            <CButton
                                              onClick={() =>
                                                PostPolilineContinueByLatLangCoupler(
                                                  Cdata.latlang.lat,
                                                  Cdata.latlang.lang,
                                                  Cdata.id,
                                                  data
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#00A65A",
                                                color: "white",
                                                borderRadius: 15,
                                                margin: 2,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </CRow>
                                <br />
                                <CRow>
                                  <CCol>
                                    <CButton
                                      block
                                      style={{
                                        backgroundColor: "#4833FF",
                                        color: "white",
                                      }}
                                      variant="outline"
                                      onClick={() =>
                                        AddORWhenLineDrawing(
                                          data,
                                          LatLngData[LatLngData.length - 1],
                                          "optical_reciver"
                                        )
                                      }
                                    >
                                      Add New Optical Receiver
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#00A65A",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setORPcollapse(!ORPcollapse)}
                >
                  Optical Receiver Passive
                </CCardHeader>
                <CCollapse show={ORPcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.optical_reciver_passive.map(
                                    (Cdata, i) => {
                                      return (
                                        <div>
                                          {Cdata.device_status === "Disable" ? (
                                            <CButton
                                              disabled
                                              color="info"
                                              style={{
                                                margin: 2,
                                                borderRadius: 15,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          ) : (
                                            <CButton
                                              onClick={() =>
                                                PostPolilineContinueByLatLangCoupler(
                                                  Cdata.latlang.lat,
                                                  Cdata.latlang.lang,
                                                  Cdata.id,
                                                  data
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#00A65A",
                                                color: "white",
                                                borderRadius: 15,
                                                margin: 2,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </CRow>
                                <br />
                                <CRow>
                                  <CCol>
                                    <CButton
                                      block
                                      style={{
                                        backgroundColor: "#4833FF",
                                        color: "white",
                                      }}
                                      variant="outline"
                                      onClick={() =>
                                        AddORWhenLineDrawing(
                                          data,
                                          LatLngData[LatLngData.length - 1],
                                          "optical_reciver_passive"
                                        )
                                      }
                                    >
                                      Add New Optical Receiver Passive
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            {mapDataFinal.port.wdm_status === 1 ? (
              <>
                <CCol xs="12" sm="6" md="6">
                  <CCard>
                    <CCardHeader
                      style={{
                        backgroundColor: "#207784",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => setONUcollapse(!ONUcollapse)}
                    >
                      ONU
                    </CCardHeader>
                    <CCollapse show={ONUcollapse}>
                      <CCardBody>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow>
                                  <CCol>
                                    <CRow style={{ marginLeft: 1 }}>
                                      {mapDataFinal.onu.map((Cdata, i) => {
                                        return (
                                          <div>
                                            {Cdata.device_status ===
                                            "Disable" ? (
                                              <CButton
                                                disabled
                                                color="info"
                                                style={{
                                                  margin: 2,
                                                  borderRadius: 15,
                                                }}
                                              >
                                                {Cdata.name}
                                              </CButton>
                                            ) : (
                                              <CButton
                                                onClick={() =>
                                                  PostPolilineContinueByLatLangCoupler(
                                                    Cdata.latlang.lat,
                                                    Cdata.latlang.lang,
                                                    Cdata.id,
                                                    data
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: "#207784",
                                                  color: "white",
                                                  borderRadius: 15,
                                                  margin: 2,
                                                }}
                                              >
                                                {Cdata.name}
                                              </CButton>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </CRow>
                                    <br />
                                    <CRow>
                                      <CCol>
                                        <CButton
                                          block
                                          style={{
                                            backgroundColor: "#4833FF",
                                            color: "white",
                                          }}
                                          variant="outline"
                                          onClick={() =>
                                            AddONUWhenLineDrawing(
                                              data,
                                              LatLngData[LatLngData.length - 1]
                                            )
                                          }
                                        >
                                          Add New ONU
                                        </CButton>
                                      </CCol>
                                    </CRow>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </CCol>
              </>
            ) : (
              <></>
            )}

            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#f599c2",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setPLCScollapse(!PLCScollapse)}
                >
                  PLC Splitter
                </CCardHeader>
                <CCollapse show={PLCScollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.plc_splitter.map((Cdata, i) => {
                                    return (
                                      <div>
                                        {Cdata.device_status === "Disable" ? (
                                          <CButton
                                            disabled
                                            color="info"
                                            style={{
                                              margin: 2,
                                              borderRadius: 15,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        ) : (
                                          <CButton
                                            onClick={() =>
                                              PostPolilineContinueByLatLangCoupler(
                                                Cdata.latlang.lat,
                                                Cdata.latlang.lang,
                                                Cdata.id,
                                                data
                                              )
                                            }
                                            style={{
                                              backgroundColor: "#f599c2",
                                              color: "white",
                                              borderRadius: 15,
                                              margin: 2,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        )}
                                      </div>
                                    );
                                  })}
                                </CRow>
                                <br />
                                <CRow>
                                  <CCol>
                                    <CButton
                                      block
                                      style={{
                                        backgroundColor: "#4833FF",
                                        color: "white",
                                      }}
                                      variant="outline"
                                      onClick={() =>
                                        AddPLCSWhenLineDrawing(
                                          data,
                                          LatLngData[LatLngData.length - 1]
                                        )
                                      }
                                    >
                                      Add New PLC Splitter
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal> */}
    </div>
  );
};

export default AddDevicesModalFromFiberLine;
